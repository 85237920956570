import { FC, MouseEvent, useEffect, useRef } from 'react';

import { useActiveCategory } from 'hooks/useActiveCategory';
import { ICategory } from 'types/categories';

import CategoryItem from './CategoryItem';

import styles from './Categories.module.scss';

export interface ICategoriesProps {
  categories: ICategory[];
}

const Categories: FC<ICategoriesProps> = ({ categories }) => {
  const [active, setActive] = useActiveCategory([categories]);
  const menuItemsRef = useRef<HTMLUListElement>(null);

  const scrollIntoViewWithOffset = (event: MouseEvent<HTMLLIElement>) => {
    const evtTarget = event.currentTarget as HTMLLIElement;
    const target = document.getElementById(evtTarget.dataset.target as string);

    if (target) {
      const top =
        target.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        160; // height of top fixed bar

      window?.scrollTo({
        top: top,
        behavior: 'smooth',
      });
    }
    setActive(evtTarget.dataset.target);
  };

  useEffect(() => {
    if (categories.length) {
      setActive(String(categories[0].id));
    }
  }, [categories]);

  useEffect(() => {
    const handleScrollToActive = () => {
      const activeElement = menuItemsRef?.current?.querySelector(
        '.active'
      ) as HTMLElement;

      const menu = menuItemsRef?.current;

      if (activeElement && menu) {
        const left = activeElement.offsetLeft;

        menu.scrollTo({
          left:
            left -
            Math.ceil(menu.clientWidth / 2 - activeElement.clientWidth / 2),
          behavior: 'smooth',
        });
      }
    };

    handleScrollToActive();
  }, [active]);

  return (
    <div className={styles.categories}>
      <ul ref={menuItemsRef} className={styles.categories__list}>
        {categories.map((category) => (
          <CategoryItem
            key={category.id}
            id={category.id}
            name={category.name}
            isActive={Number(active) === category.id}
            onClick={scrollIntoViewWithOffset}
          />
        ))}
      </ul>
    </div>
  );
};

export default Categories;
