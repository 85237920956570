import { useContext } from 'react';

import Balance from 'components/Balance';
import History from 'components/History';
import OrdersButton from 'components/OrdersButton';
import ShopButton from 'components/ShopButton';
import Text from 'components/ui/Text';
import { InitDataContext } from 'context/initDataContext';

import styles from './HomePage.module.scss';

const HomePage = () => {
  const { balance } = useContext(InitDataContext);

  return (
    <div className={styles.home_page}>
      <Balance {...balance} />
      <div className={styles.home_page__buttons}>
        <ShopButton />
        <OrdersButton
          type="link"
          to="/orders"
          variant="green"
          className={styles.home_page__buttons__order}
        >
          <Text as="span" variant="subtitle-3">
            Заказы
          </Text>
        </OrdersButton>
      </div>
      <History />
    </div>
  );
};

export default HomePage;
