import { useContext, useEffect, useState } from 'react';

import { getUserOrders } from 'api/users';
import BackButton from 'components/BackButton';
import OrderList from 'components/OrderList';
import Text from 'components/ui/Text';
import { InitDataContext } from 'context/initDataContext';
import { IOrder } from 'types/users';

import styles from './OrdersPage.module.scss';

const OrdersPage = () => {
  const [isLoading, setLoading] = useState(true);
  const { telegramId } = useContext(InitDataContext);

  const [orders, setOrders] = useState<IOrder[]>([]);

  useEffect(() => {
    if (telegramId) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const { data } = await getUserOrders();

          const sortedData = data.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );

          setOrders(sortedData);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [telegramId]);

  if (isLoading) return null;

  return (
    <div className={styles.order_page}>
      <div className={styles.order_page__navigation}>
        <BackButton className={styles.back_button} />
      </div>
      <div className={styles.order_page__title}>
        <Text variant="h1" as="h2" className={styles.order_page__title__text}>
          Мои заказы
          <span className={styles.order_page__title__text__count}>
            {orders.length}
          </span>
        </Text>
      </div>
      <OrderList orders={orders} />
    </div>
  );
};

export default OrdersPage;
