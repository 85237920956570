import { CURRENCY_EMOJI } from 'constants/currency';

import { FC } from 'react';

import cn from 'clsx';
import { IOrderPopupProps } from 'components/OrderPopup';
import OrdersButton from 'components/OrdersButton';
import Text from 'components/ui/Text';

import styles from '../OrderPopup.module.scss';

const InitialOrderPopup: FC<
  Omit<IOrderPopupProps, 'type' | 'onClosePopup'>
> = ({ currency, price, onClickAccept, onClickDecline }) => (
  <>
    <div className={cn(styles.title, styles.title__initial)}>
      <Text variant="h3">Вы точно уверены?</Text>
    </div>
    <div className={styles.initial_text_block}>
      <Text
        variant="body-1"
        className={cn(styles.description, styles.description__initial)}
      >
        Вы потратите
      </Text>
      <div className={styles.initial_text_block__right}>
        <Text variant="h4" className={styles.initial_text_block_price}>
          {price}
        </Text>
        <div>{currency && CURRENCY_EMOJI[currency.name]}</div>
      </div>
    </div>
    <div className={styles.actions}>
      <OrdersButton
        className={cn(styles.actions__button)}
        type="button"
        onClick={onClickAccept}
        variant="primary"
      >
        <Text as="span" variant="subtitle-3">
          Да
        </Text>
      </OrdersButton>
      <OrdersButton
        className={styles.actions__button}
        type="button"
        onClick={onClickDecline}
        variant="secondary"
      >
        <Text as="span" variant="subtitle-3">
          Вернуться назад
        </Text>
      </OrdersButton>
    </div>
  </>
);

export default InitialOrderPopup;
