import { FC } from 'react';

import PopUpItemButton from 'components/PopUpItemButton';
import Text from 'components/ui/Text';
import { ICurrency } from 'types/currency';

import styles from './CardPopup.module.scss';

interface ICardPopupProps {
  onClick: () => void;
  imageUrl?: string;
  name?: string;
  description?: string;
  currency?: ICurrency;
  price?: string | number;
}

const CardPopup: FC<ICardPopupProps> = ({
  imageUrl,
  name,
  description,
  currency,
  price,
  onClick,
}) => (
  <div className={styles.card_popup}>
    <div className={styles.card_popup__content}>
      <div className={styles.card_popup__image}>
        <img
          src={imageUrl || '/images/placeholder_image.png'}
          alt={name}
          onLoad={() => {
            console.log('loaded');
          }}
        />
      </div>
      <div className={styles.card_popup__text}>
        <Text variant="h1" className={styles.card_popup__text__title}>
          {name}
        </Text>
        <Text variant="body-1" className={styles.card_popup__text__description}>
          {description}
        </Text>
      </div>
    </div>
    <PopUpItemButton
      onClick={onClick}
      currency={currency?.name}
      price={price}
    />
  </div>
);

export default CardPopup;
