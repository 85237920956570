export const CURRENCY_WORD_FORM = {
  diamonds: {
    1: 'алмаз',
    2: 'алмаза',
    5: 'алмазов',
  },
  coins: {
    1: 'джинкоин',
    2: 'джинкоина',
    5: 'джинкоинов',
  },
  thanks: {
    1: 'спасибо',
    2: 'спасибо',
    5: 'спасибо',
  },
};

export const CURRENCY_EMOJI = {
  diamonds: '💎',
  coins: '💰',
  thanks: '🙏🏻',
};
