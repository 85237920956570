import { useContext, useEffect, useState } from 'react';

import { getUserTransactions } from 'api/users';
import HistoryCard from 'components/History/HistoryCard';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Text from 'components/ui/Text';
import { InitDataContext } from 'context/initDataContext';
import { formatTransactionData } from 'helpers/formatTransactionData';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ITransactionDataFormatted } from 'types/users';

import HistoryDetails from './HistoryDetails';

import styles from './History.module.scss';

const History = () => {
  const { telegramId } = useContext(InitDataContext);
  const [isHistoryCardDetailsOpen, setHistoryCardDetailsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [selectedHistoryItem, setSelectedHistoryItem] =
    useState<ITransactionDataFormatted | null>(null);

  const [transactions, setTransactions] = useState<ITransactionDataFormatted[]>(
    []
  );

  const fetchData = async () => {
    try {
      const { data } = await getUserTransactions(10, currentPage);
      setTotal(data.total);

      setTransactions((currentTransactions) => [
        ...currentTransactions,
        ...formatTransactionData(data.result),
      ]);

      setCurrentPage((currentPage) => currentPage + 1);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log('fetch :>> ', transactions);
    fetchData();
  }, [telegramId]);

  const refetchData = async () => {
    console.log('refetch :>> ');
    fetchData();
  };

  const handleCloseTransactionDetails = () => {
    setHistoryCardDetailsOpen(false);

    setTimeout(() => {
      setSelectedHistoryItem(null);
    }, 300);
  };

  const handleOpenHistoryCardDetails = (item: ITransactionDataFormatted) => {
    setSelectedHistoryItem(item);
    setHistoryCardDetailsOpen(true);
  };

  return (
    <div className={styles.history} id="scrollableDiv">
      <Text as="h2" variant="h1" className={styles.history__title}>
        История
      </Text>
      <InfiniteScroll
        dataLength={transactions.length}
        next={refetchData}
        hasMore={transactions.length !== total}
        loader={
          <div className={styles.history__spinner}>
            <Spinner />
          </div>
        }
      >
        {transactions.map((item) => (
          <HistoryCard
            key={item.id}
            onHistoryCardClick={handleOpenHistoryCardDetails}
            transaction={item}
          />
        ))}
      </InfiniteScroll>
      <Modal
        isOpen={isHistoryCardDetailsOpen}
        onClose={handleCloseTransactionDetails}
        modalClassName={styles.transaction__modal}
      >
        {selectedHistoryItem && (
          <HistoryDetails
            transaction={selectedHistoryItem!}
            onClose={handleCloseTransactionDetails}
          />
        )}
      </Modal>
    </div>
  );
};

export default History;
