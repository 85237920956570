import { FC } from 'react';

import ItemCardButton from 'components/ItemCardButton';
import Text from 'components/ui/Text';
import { ICurrency } from 'types/currency';
import { ID } from 'types/users';

import styles from './ShopCard.module.scss';

export interface IShopCardProps {
  imageUrl: string;
  name: string;
  description: string;
  price: number;
  currency: ICurrency;
  onSelectProduct: (productId: number, categoryId: ID) => void;
  productId: number;
  categoryId: ID;
}

const ShopCard: FC<IShopCardProps> = ({
  imageUrl,
  name,
  description,
  price,
  currency,
  onSelectProduct,
  productId,
  categoryId,
}) => (
  <div
    className={styles.shop_card}
    onClick={() => onSelectProduct(productId, categoryId)}
  >
    <div className={styles.shop_card__image}>
      <img
        src={imageUrl || '/images/placeholder_image.png'}
        alt={name}
        className={styles.img}
      />
    </div>
    <div className={styles.shop_card__text}>
      <Text
        as="span"
        variant="subtitle-4"
        className={styles.shop_card__text__title}
      >
        {name}
      </Text>
      <Text variant="title-1" className={styles.shop_card__text__description}>
        {description}
      </Text>
    </div>
    <ItemCardButton currency={currency.name}>{price}</ItemCardButton>
  </div>
);

export default ShopCard;
