import api from 'api/index';
import { AxiosResponse } from 'axios';
import { IOrder } from 'types/users';

export const postOrder = async (productId: number) => {
  const { data } = await api.post<AxiosResponse<IOrder>>('api/orders', {
    productId,
  });

  return data;
};
