import { ReactNode, JSX, ComponentType, createElement } from 'react';

import cn from 'clsx';

import styles from './Text.module.scss';

const Variants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'subtitle-1',
  'subtitle-2',
  'subtitle-3',
  'subtitle-4',
  'body-1',
  'body-2',
  'title-1',
  'title-2',
  'main-balance-emoji',
  'shop-balance-emoji',
] as const;

type PropsOf<Tag> = Tag extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[Tag]
  : Tag extends ComponentType<infer Props>
    ? Props & JSX.IntrinsicAttributes
    : never;

type TVariants = (typeof Variants)[number];

interface ITextProps<T extends keyof JSX.IntrinsicElements> {
  as?: T;
  variant?: TVariants;
  children?: string | ReactNode;
  className?: string;
}

const Text = <T extends keyof JSX.IntrinsicElements>({
  as,
  variant = 'body-1',
  children,
  className,
  ...rest
}: ITextProps<T> & PropsOf<T>) => {
  const textStyles = cn(styles[variant], className);

  const el = createElement(
    as || 'p',
    {
      className: textStyles,
      ...rest,
    },
    children
  );

  return <>{el}</>;
};

export default Text;
