import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './styles/global.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <WebAppProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </WebAppProvider>
  </StrictMode>
);

reportWebVitals();
