function leadZero(n: number): string {
  return n < 10 ? '0' + n : '' + n;
}

export function formatDate(dateString: string) {
  const targetDate = new Date(dateString);

  const formattedDayMonth = `${leadZero(targetDate.getDate())}.${leadZero(
    targetDate.getMonth() + 1
  )}`;

  const formattedDayMonthYear = `${formattedDayMonth}.${targetDate.getFullYear()}`;

  return formattedDayMonthYear;
}
