import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useExpand } from '@vkruglikov/react-telegram-web-app';
import { getUserBalance } from 'api/users';
import { InitDataContext } from 'context/initDataContext';

import styles from './Layout.module.scss';

const Layout = () => {
  const { telegramId, setBalance } = useContext(InitDataContext);

  const [_isExpanded, expand] = useExpand();

  useEffect(() => {
    expand();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getUserBalance();
        setBalance(data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, [telegramId]);

  return (
    <div className={styles.layout}>
      <Outlet />
    </div>
  );
};

export default Layout;
