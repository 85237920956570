import { CURRENCY_EMOJI } from 'constants/currency';

import { useContext } from 'react';

import Text from 'components/ui/Text';
import { InitDataContext } from 'context/initDataContext';

import styles from './ShopBalance.module.scss';

const ShopBalance = () => {
  const { balance } = useContext(InitDataContext);

  return (
    <div className={styles.balance}>
      <div className={styles.item}>
        <Text variant="h4">{balance.balanceDiamonds}</Text>
        <Text variant="shop-balance-emoji">{CURRENCY_EMOJI.diamonds}</Text>
      </div>
      <div className={styles.item}>
        <Text variant="h4">{balance.balanceCoins}</Text>
        <Text variant="shop-balance-emoji">{CURRENCY_EMOJI.coins}</Text>
      </div>
      <div className={styles.item}>
        <Text variant="h4">{balance.balanceThanks}</Text>
        <Text variant="shop-balance-emoji">{CURRENCY_EMOJI.thanks}</Text>
      </div>
    </div>
  );
};

export default ShopBalance;
