import { FC, MouseEvent } from 'react';

import cn from 'clsx';
import Text from 'components/ui/Text';
import { ID } from 'types/users';

import styles from '../Categories.module.scss';

export interface ICategoryItemProps {
  id: ID;
  name: string;
  onClick: (event: MouseEvent<HTMLLIElement>) => void;
  isActive?: boolean;
}

const CategoryItem: FC<ICategoryItemProps> = ({
  id,
  name,
  onClick,
  isActive = false,
}) => {
  const categoryItemClasses = cn(styles.categories__item, {
    active: isActive,
  });

  return (
    <li className={categoryItemClasses} data-target={id} onClick={onClick}>
      <Text as="span" variant="subtitle-3">
        {name}
      </Text>
    </li>
  );
};

export default CategoryItem;
