import { CURRENCY_EMOJI } from 'constants/currency';

import { FC } from 'react';

import Button from 'components/ui/Button';
import Text from 'components/ui/Text';
import { TCurrency } from 'types/currency';

import styles from './PopUpItemButton.module.scss';

interface IPopUpItemButtonProps {
  currency?: TCurrency;
  price?: string | number;
  onClick?: () => void;
}

const PopUpItemButton: FC<IPopUpItemButtonProps> = ({
  currency,
  price,
  ...props
}) => (
  <Button className={styles.button} {...props}>
    <Text as="span" variant="h4" className={styles.text_description}>
      Приобрести за
    </Text>
    <Text as="span" variant="h4" className={styles.text_price}>
      {price}
    </Text>
    <Text variant="h4">{currency && CURRENCY_EMOJI[currency]}</Text>
  </Button>
);

export default PopUpItemButton;
