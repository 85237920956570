import { ORDER_STATUS_TRANSLATION } from 'constants/order';

import { FC } from 'react';

import cn from 'clsx';
import { ReactComponent as HintIcon } from 'components/icons/svg/hint.svg';
import Text from 'components/ui/Text';
import { Tooltip } from 'react-tooltip';
import { TProgressStages } from 'types/progressStages';
import { ID } from 'types/users';

import styles from './OrderStatus.module.scss';

interface IOrderStatusProps {
  id: ID;
  status: TProgressStages;
  hint: string;
}

const OrderStatus: FC<IOrderStatusProps> = ({ id, status, hint }) => (
  <div
    className={styles.order_status}
    data-tooltip-id={`order-status-hint-${id}`}
    data-tooltip-place="top-end"
  >
    <div className={cn(styles.order_status__badge, styles[status])}>
      <Text as="span" variant="title-2">
        {ORDER_STATUS_TRANSLATION[status]}
      </Text>
      {status === 'CANCELED' && (
        <>
          <HintIcon />
          <Tooltip
            className={styles.order_status__tooltip}
            id={`order-status-hint-${id}`}
          >
            <Text variant="title-1">{hint}</Text>
          </Tooltip>
        </>
      )}
    </div>
  </div>
);

export default OrderStatus;
