import { FC, ReactNode, useEffect } from 'react';

import cn from 'clsx';
import { ReactComponent as Close } from 'components/icons/svg/close.svg';
import { createPortal } from 'react-dom';

import useMountTransition from '../../hooks/useMountTransition';

import styles from './Modal.module.scss';

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  withCloseIcon?: boolean;
  isSlide?: boolean;
  children?: ReactNode;
  containerClassName?: string;
  contentClassName?: string;
  modalClassName?: string;
}

const Modal: FC<IModalProps> = ({
  isOpen,
  onClose,
  withCloseIcon = false,
  children,
  containerClassName,
  contentClassName,
  modalClassName,
}) => {
  const isTransitioning = useMountTransition(isOpen, 300);

  const containerClass = cn(styles.container, containerClassName, {
    [styles.open]: isOpen,
    [styles.in]: isTransitioning,
  });

  const contentClass = cn(styles.modal__content, contentClassName);

  const modalClass = cn(styles.modal, modalClassName);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  if (!isTransitioning && !isOpen) {
    return null;
  }

  return createPortal(
    <div className={containerClass}>
      <div className={modalClass}>
        {withCloseIcon ? (
          <div className={styles.close} onClick={onClose}>
            <Close />
          </div>
        ) : null}
        <div className={contentClass}>{children}</div>
      </div>
      <div onClick={onClose} className={styles.backdrop} />
    </div>,
    document.body
  );
};

export default Modal;
