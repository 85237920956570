import { MAX_RETRY_ATTEMPTS } from 'constants/api';

import { telegramUserAuth } from 'api/auth';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      const token = await telegramUserAuth();
      originalRequest.retryAttempts = originalRequest.retryAttempts || 0;

      if (originalRequest.retryAttempts < MAX_RETRY_ATTEMPTS) {
        originalRequest.headers['Authorization'] = `Bearer ${token}`;

        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        originalRequest.retryAttempts++;

        return instance(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
