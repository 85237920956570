import { Route, Routes } from 'react-router-dom';

import HomePage from 'components/HomePage';
import Layout from 'components/Layout';
import OrdersPage from 'components/OrdersPage';
import ShopPage from 'components/ShopPage';
import { InitDataContextProvider } from 'context/initDataContext';

function App() {
  return (
    <InitDataContextProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/orders" element={<OrdersPage />} />
          <Route path="/shop" element={<ShopPage />} />
        </Route>
      </Routes>
    </InitDataContextProvider>
  );
}

export default App;
