import api from 'api';
import { AxiosResponse } from 'axios';
import { IBalance } from 'types/balance';
import { IOrder, IPaginationData, ITransaction } from 'types/users';

export const getUserBalance = (): Promise<AxiosResponse<IBalance>> =>
  api.get('api/users/balance').then(({ data }) => data);

export const getUserTransactions = (
  pageSize?: number,
  page?: number
): Promise<AxiosResponse<IPaginationData<ITransaction>>> =>
  api
    .get('api/users/transactions', { params: { pageSize, page } })
    .then(({ data }) => data);

export const getUserOrders = (): Promise<AxiosResponse<IOrder[]>> =>
  api.get('api/users/orders').then(({ data }) => data);
