import { FC } from 'react';

import BalanceCurrency from 'components/Balance/BalanceCurrency';
import Text from 'components/ui/Text';
import { IBalance } from 'types/balance';

import styles from './Balance.module.scss';

type TBalanceProps = IBalance;

const Balance: FC<TBalanceProps> = ({
  balanceDiamonds,
  balanceCoins,
  balanceThanks,
}) => (
  <div className={styles.balance}>
    <Text as="h2" variant="h1" className={styles.balance__title}>
      Баланс
    </Text>
    <div className={styles.balance__container}>
      <BalanceCurrency currency="diamonds" value={balanceDiamonds} />
      <div className={styles.divider}>
        <div className={styles.divider__line} />
      </div>
      <BalanceCurrency currency="coins" value={balanceCoins} />
      <div className={styles.divider}>
        <div className={styles.divider__line} />
      </div>
      <BalanceCurrency currency="thanks" value={balanceThanks} />
    </div>
  </div>
);

export default Balance;
