import { FC } from 'react';
import { Link } from 'react-router-dom';

import cn from 'clsx';
import { ReactComponent as ArrowLeftIcon } from 'components/icons/svg/arrowLeftIcon.svg';

import styles from './BackButton.module.scss';

interface INavigationButtonProps {
  className?: string;
  onClick?: () => void;
}

const BackButton: FC<INavigationButtonProps> = ({ className, ...props }) => {
  const backButtonClassName = cn(styles.back_button, className);

  return (
    <Link to="/" className={backButtonClassName} {...props}>
      <ArrowLeftIcon />
    </Link>
  );
};

export default BackButton;
