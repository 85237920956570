import { BALANCE_OPERATIONS_TRANSLATIONS } from 'constants/balance';
import { CURRENCY_EMOJI } from 'constants/currency';
import { TRANSACTION_DETAILS } from 'constants/transactions';

import { FC } from 'react';

import cn from 'clsx';
import Button from 'components/ui/Button';
import Text from 'components/ui/Text';
import { ITransactionDataFormatted } from 'types/users';

import styles from './HistoryDetails.module.scss';

interface IHistoryDetailsProps {
  transaction: ITransactionDataFormatted;
  onClose: () => void;
}

const HistoryDetails: FC<IHistoryDetailsProps> = ({ transaction, onClose }) => {
  const {
    amount,
    operationType,
    currency,
    date,
    details,
    description,
    tipsTransactions,
    userId,
  } = transaction;

  const renderDescription = () => {
    if (!details || Object.keys(TRANSACTION_DETAILS).includes(details)) {
      return description || '-';
    }

    return details;
  };

  return (
    <div className={styles.history_details}>
      <div className={styles.history_details__title}>
        <Text as="span" variant="h3">
          {BALANCE_OPERATIONS_TRANSLATIONS[operationType]}
        </Text>
      </div>
      <div className={styles.history_details__content}>
        <div className={styles.amount}>
          <Text as="span" variant="body-1" className={styles.label}>
            Кол-во:
          </Text>
          <div
            className={cn(styles.currency, {
              [styles.positive]: Number(amount) > 0,
              [styles.negative]: Number(amount) < 0,
            })}
          >
            <Text as="span" variant="h4">
              {Number(amount) > 0 && '+'}
              {amount}
            </Text>
            <Text as="span" variant="h4">
              {CURRENCY_EMOJI[currency]}
            </Text>
          </div>
        </div>
        <div className={styles.date}>
          <Text as="span" variant="body-1" className={styles.label}>
            Дата:
          </Text>
          <Text as="span" variant="h4">
            {date}
          </Text>
        </div>
        {tipsTransactions && (
          <div className={styles.thanks}>
            <Text as="span" variant="body-1" className={styles.label}>
              {tipsTransactions.tips.receiver.id === userId
                ? 'От кого:'
                : 'Кому:'}
            </Text>
            <Text as="span" variant="h4">
              {tipsTransactions.tips.receiver.id === userId
                ? tipsTransactions.tips.sender.fullName
                : tipsTransactions.tips.receiver.fullName}
            </Text>
          </div>
        )}
        <div className={styles.description}>
          <Text as="span" variant="body-1" className={styles.label}>
            Описание:
          </Text>
          <Text variant="h4">{renderDescription()}</Text>
        </div>
      </div>
      <div className={styles.history_details__actions}>
        <Button className={styles.button} type="button" onClick={onClose}>
          <Text as="span" variant="subtitle-3">
            Вернуться назад
          </Text>
        </Button>
      </div>
    </div>
  );
};

export default HistoryDetails;
