import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import cn from 'clsx';
import Button from 'components/ui/Button';

import styles from './OrdersButton.module.scss';

type TOrderButtonVariant = 'primary' | 'secondary' | 'green';
type TOrderButtonType = 'link' | 'button';

type TButton =
  | { type: 'link'; to: string }
  | { type: 'button'; onClick?: () => void };

interface IOrderButtonMainProps {
  variant: TOrderButtonVariant;
  type: TOrderButtonType;
  children?: ReactNode;
  className?: string;
}

type IOrderButtonProps = IOrderButtonMainProps & TButton;

const OrdersButton: FC<IOrderButtonProps> = ({
  type = 'button',
  variant = 'primary',
  className,
  children,
  ...otherProps
}) => {
  const buttonClassName = cn(styles.button, styles[variant], className);

  if (type === 'link') {
    const linkClassName = cn(buttonClassName, styles.button__link);

    return (
      // @ts-ignore todo ignore
      <Link className={linkClassName} {...otherProps}>
        {children}
      </Link>
    );
  }

  return (
    <Button className={buttonClassName} {...otherProps}>
      {children}
    </Button>
  );
};

export default OrdersButton;
