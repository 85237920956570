import { FC } from 'react';

import { IOrderPopupProps } from 'components/OrderPopup';
import OrdersButton from 'components/OrdersButton';
import Text from 'components/ui/Text';

import styles from '../OrderPopup.module.scss';

const SuccessfulOrderPopup: FC<Pick<IOrderPopupProps, 'onClosePopup'>> = ({
  onClosePopup,
}) => (
  <>
    <div className={styles.emoji_block}>
      <Text as="span" className={styles.emoji_block_text}>
        🎂
      </Text>
    </div>
    <div className={styles.text}>
      <Text variant="h2" className={styles.title}>
        Поздравляем!
      </Text>
      <Text variant="body-1" className={styles.description}>
        Скоро с тобой свяжется менеджер
      </Text>
    </div>
    <OrdersButton
      className={styles.actions__button}
      type="button"
      onClick={onClosePopup}
      variant="primary"
    >
      <Text as="span" variant="subtitle-3">
        Хорошо
      </Text>
    </OrdersButton>
  </>
);

export default SuccessfulOrderPopup;
