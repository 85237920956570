import api from 'api/index';
import { AxiosResponse } from 'axios';
import { IProduct } from 'types/products';
import { IPaginationData } from 'types/users';

export const getProducts = async () => {
  const { data } =
    await api.get<AxiosResponse<IPaginationData<IProduct>>>('api/products');

  return data.data;
};
