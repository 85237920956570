import { CURRENCY_EMOJI, CURRENCY_WORD_FORM } from 'constants/currency';

import { FC } from 'react';

import IconWrapper from 'components/ui/IconWrapper';
import Text from 'components/ui/Text';
import { TCurrency } from 'types/currency';
import { getWordForm } from 'utils/getWordForm';

import styles from './BalanceCurrency.module.scss';

interface IBalanceCurrencyProps {
  currency: TCurrency;
  value: number;
}

const BalanceCurrency: FC<IBalanceCurrencyProps> = ({ currency, value }) => (
  <div className={styles.currency}>
    <IconWrapper>
      <Text as="span" variant="main-balance-emoji">
        {CURRENCY_EMOJI[currency]}
      </Text>
    </IconWrapper>
    <div className={styles.currency_text}>
      <Text
        as="span"
        className={styles.currency_text_price}
        variant="subtitle-1"
      >
        {value}
      </Text>
      <Text
        as="span"
        className={styles.currency_text_currency_name}
        variant="title-1"
      >
        {getWordForm(value, CURRENCY_WORD_FORM[currency])}
      </Text>
    </div>
  </div>
);

export default BalanceCurrency;
