import { Link } from 'react-router-dom';

import Text from 'components/ui/Text';

import styles from './ShopButton.module.scss';

const ShopButton = () => (
  <Link to="/shop" className={styles.shop_button}>
    <div className={styles.content}>
      <div className={styles.content_icon}>
        <img src="shop_icon.png" alt="shop icon" />
      </div>
      <Text as="span" variant="subtitle-2" className={styles.content_text}>
        Магазин
      </Text>
    </div>
  </Link>
);

export default ShopButton;
