import { BALANCE_OPERATIONS_TRANSLATIONS } from 'constants/balance';
import { CURRENCY_EMOJI } from 'constants/currency';

import { FC } from 'react';

import cn from 'clsx';
import Text from 'components/ui/Text';
import { EBalanceOperation } from 'types/balance';
import { ITransactionDataFormatted } from 'types/users';

import styles from './HistoryCard.module.scss';

export type THistoryCardProps = {
  transaction: ITransactionDataFormatted;
  onHistoryCardClick: (item: ITransactionDataFormatted) => void;
};

const HistoryCard: FC<THistoryCardProps> = ({
  transaction,
  onHistoryCardClick,
}) => {
  const { operationType, currency, description, amount, date } = transaction;

  const isNegativeOperation =
    operationType === EBalanceOperation.withdrawal ||
    operationType === EBalanceOperation.acknowledgement ||
    operationType === EBalanceOperation.monthly_burning;

  const amountClassName = cn({
    [styles.amount__positive]: !isNegativeOperation,
    [styles.amount__negative]: isNegativeOperation,
  });

  return (
    <div
      className={styles.card}
      onClick={() => onHistoryCardClick(transaction)}
    >
      <div className={styles.card__left}>
        <div className={styles.icon}>
          <Text variant="subtitle-2" as="span">
            {CURRENCY_EMOJI[currency]}
          </Text>
        </div>
        <div className={styles.text}>
          <Text as="span" variant="subtitle-3" className={styles.text__title}>
            {BALANCE_OPERATIONS_TRANSLATIONS[operationType]}
          </Text>
          <Text
            as="span"
            variant="title-1"
            className={styles.text__description}
          >
            {description}
          </Text>
        </div>
      </div>
      <div className={styles.card__right}>
        <Text as="span" variant="subtitle-3" className={amountClassName}>
          {!isNegativeOperation && '+'}
          {amount}
        </Text>
        <Text as="span" variant="title-1" className={styles.date}>
          {date}
        </Text>
      </div>
    </div>
  );
};

export default HistoryCard;
