import { CURRENCY_EMOJI } from 'constants/currency';

import { FC } from 'react';

import { IOrderPopupProps } from 'components/OrderPopup';
import OrdersButton from 'components/OrdersButton';
import Text from 'components/ui/Text';

import styles from '../OrderPopup.module.scss';

const InsufficientBalancePopup: FC<
  Pick<IOrderPopupProps, 'onClosePopup' | 'currency'>
> = ({ currency, onClosePopup }) => (
  <>
    <div className={styles.emoji_block}>
      <Text as="span" className={styles.emoji_block_text}>
        😔
      </Text>
    </div>
    <div className={styles.text}>
      <Text variant="h3" className={styles.title}>
        Чуть-чуть не хватает&#40;
      </Text>
      <Text variant="body-1" className={styles.description}>
        На балансе недостаточно: {currency && CURRENCY_EMOJI[currency.name]}
      </Text>
    </div>
    <OrdersButton
      className={styles.actions__button}
      type="button"
      onClick={onClosePopup}
      variant="primary"
    >
      <Text as="span" variant="subtitle-3">
        Хорошо
      </Text>
    </OrdersButton>
  </>
);

export default InsufficientBalancePopup;
