type WordForms = {
  1: string;
  2: string;
  5: string;
};

export const getWordForm = (number: number, words: WordForms) => {
  const value = Math.abs(number) % 100;
  const num = value % 10;

  if (value > 10 && value < 20) return words[5];

  if (num > 1 && num < 5) return words[2];

  if (num === 1) return words[1];

  return words[5];
};
