import { useEffect, DependencyList, useState, useRef } from 'react';

import { useDebounce } from './useDebounce';

let sections: Array<Element>;

export const useActiveCategory = (
  deps: DependencyList = []
): [string | undefined, (value?: string) => void] => {
  const [active, setActive] = useState<string | undefined>('');
  const isSelected = useRef(false);

  const debouncedActive = useDebounce<string>(
    active || '',
    isSelected.current ? 1000 : undefined
  );

  function isInView(element: Element) {
    const rect = element.getBoundingClientRect();

    // distance just below the middle of the screen
    if (rect.bottom >= 350) {
      return element.id;
    }

    return null;
  }

  function handleScroll() {
    if (isSelected.current) return;

    const found = sections.find((section) => isInView(section) === section.id);

    setActive(found?.id);
  }

  function handleSetActive(value?: string) {
    isSelected.current = true;

    setActive(value);
  }

  useEffect(() => {
    isSelected.current = false;
  }, [debouncedActive]);

  useEffect(() => {
    sections = Array.from(document?.querySelectorAll('.category'));

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, deps);

  return [active, handleSetActive];
};
