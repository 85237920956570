import cn from 'clsx';

import styles from './spinner.module.scss';

interface ISpinnerProps {
  size?: 'small' | 'medium' | 'large';
}

const Spinner = ({ size = 'medium' }: ISpinnerProps) => {
  const spinnerClassName = cn(styles.spinner, styles[size]);

  return <span className={spinnerClassName} />;
};

export default Spinner;
