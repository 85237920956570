import { FC } from 'react';

import { IOrder } from 'types/users';

import OrderItem from './OrderItem';

import styles from './OrderList.module.scss';

interface IOrderListProps {
  orders: IOrder[];
}

const OrderList: FC<IOrderListProps> = ({ orders }) => (
  <div className={styles.order_list}>
    {orders.map((item) => (
      <OrderItem key={item.id} {...item} />
    ))}
  </div>
);

export default OrderList;
