import { CURRENCY_EMOJI } from 'constants/currency';

import { FC, ReactNode } from 'react';

import Button from 'components/ui/Button';
import Text from 'components/ui/Text';
import { TCurrency } from 'types/currency';

import styles from './ItemCardButton.module.scss';

interface IItemCardButtonProps {
  currency: TCurrency;
  onClick?: () => void;
  children?: string | ReactNode;
}

const ItemCardButton: FC<IItemCardButtonProps> = ({
  currency,
  children,
  ...props
}) => (
  <Button className={styles.item_card_button} {...props}>
    <Text as="span" variant="h4" className={styles.text}>
      {children}
    </Text>
    <Text variant="h4">{CURRENCY_EMOJI[currency]}</Text>
  </Button>
);

export default ItemCardButton;
