import { FC } from 'react';

import cn from 'clsx';
import ShopCard from 'components/ShopCard';
import Text from 'components/ui/Text';
import { ICategorizedProducts } from 'types/products';
import { ID } from 'types/users';

import styles from './ShopPageContent.module.scss';

interface IShopPageContentProps {
  products: ICategorizedProducts[];
  onSelectProduct: (productId: number, categoryId: ID) => void;
}

const ShopPageContent: FC<IShopPageContentProps> = ({
  products,
  onSelectProduct,
}) => (
  <div className={styles.shop_page_content}>
    {products.map((category) => (
      <div
        id={String(category.id)}
        key={category.id}
        className={cn('category', styles.category)}
      >
        <Text as="h2" variant="h2" className={styles.category__title}>
          {category.name}
        </Text>
        <div className={styles.products}>
          {category.products?.map((product) => (
            <ShopCard
              key={product.id}
              categoryId={category.id}
              productId={product.id}
              onSelectProduct={onSelectProduct}
              name={product.name}
              description={product.description}
              imageUrl={product.imageUrl}
              price={product.price}
              currency={product.currency}
            />
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default ShopPageContent;
