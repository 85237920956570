import api from 'api/index';
import { PassInitDataProvider } from 'context/initDataContext';

export const telegramUserAuth = async () => {
  const config = {
    headers: {
      'auth-telegram-data-string': PassInitDataProvider.telegramDataString,
      'auth-telegram-hash': PassInitDataProvider.telegramHash,
    },
  };

  const { data } = await api.post(
    'api/auth/login',
    {
      telegramId: PassInitDataProvider.telegramId,
    },
    config
  );

  return data.data;
};
