import { FC, ReactNode, MouseEvent, ButtonHTMLAttributes } from 'react';

import cn from 'clsx';

import styles from './Button.module.scss';

interface IBaseButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children?: string | ReactNode;
  className?: string;
}

type IButtonProps = IBaseButtonProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof IBaseButtonProps>;

const Button: FC<IButtonProps> = ({
  onClick,
  children,
  className,
  ...rest
}) => {
  const buttonClassName = cn(styles.button, className);

  return (
    <button onClick={onClick} className={buttonClassName} {...rest}>
      {children}
    </button>
  );
};

export default Button;
