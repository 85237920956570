import { ICategory } from 'types/categories';
import { ICategorizedProducts, IProduct } from 'types/products';

const sortByOrder = (
  a: ICategory | ICategorizedProducts,
  b: ICategory | ICategorizedProducts
) => {
  if (a.order < b.order) return -1;

  if (a.order > b.order) return 1;

  return 0;
};

export const productsDataFormat = (products: IProduct[]) => {
  const categoriesData: ICategory[] = [];
  const categorizedProductsData: ICategorizedProducts[] = [];

  products.forEach((product) => {
    const categoryData = product.category;

    if (!categoryData) return;

    let category = categoriesData
      .filter((_category) => _category.id)
      .find((category) => category.id === categoryData.id);

    if (!category) {
      category = categoryData;
      categoriesData.push(category);
    }

    let productsInCategory = categorizedProductsData.find(
      (cat) => cat.id === categoryData.id
    );

    if (!productsInCategory) {
      productsInCategory = { ...categoryData, products: [] };
      categorizedProductsData.push(productsInCategory);
    }

    productsInCategory.products?.push(product);
  });

  categoriesData.sort(sortByOrder);
  categorizedProductsData.sort(sortByOrder);

  return { categoriesData, categorizedProductsData };
};
